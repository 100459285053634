import React, { Fragment } from "react";

const NotFoundPage = () => {
  return (
    <Fragment>
      <main className="mainbox-background">
        <section className="mainbox">
          <div className="err">4</div>
          <i className="far fa-question-circle fa-spin"></i>
          <div className="err2">4</div>
          <div className="msg">
            Maybe this page moved? Got deleted? Is hiding out in quarantine?
            Never existed in the first place?
            <p>
              Let's go <a href="/">home</a> and try from there.
            </p>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default NotFoundPage;
